import React, { useEffect, useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import BannerWithOutBg from "../components/common/BannerWithOutBg"
import PostCard from "../components/common/PostCard"
import SEORevamp from "../components/common/SEO_Revamp"
import MainLayout from "../layouts/MainLayout"

const AuthorBlogTemplate = ({ pageContext }) => {
  const postWriter = pageContext?.item

  const [list, setList] = useState([])
  const [loadMore, setLoadMore] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const length = 9

  useEffect(() => {
    setList(pageContext?.allBlogs?.slice(0, length))
  }, [pageContext])

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list?.length
      const isMore = currentLength < pageContext?.allBlogs?.length
      const nextResults = isMore
        ? pageContext?.allBlogs?.slice(currentLength, currentLength + length)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore])

  useEffect(() => {
    const isMore = list.length < pageContext?.allBlogs?.length
    setHasMore(isMore)
  }, [list, pageContext?.allBlogs])

  const handleLoadMore = () => {
    setLoadMore(true)
  }

  return (
    <MainLayout bgChanged={false}>
      <BannerWithOutBg
        heading="Blogs"
        subHeading="Catch top stories, Stay updated with trend."
      />

      <section className="pt-0">
        <Container style={{ paddingBottom: 96 }}>
          <Row>
            {list?.length > 0 ? (
              list?.map((post, i) => (
                <Col key={i} style={{ padding: 8 }} md={6} lg={4} xl={4}>
                  <PostCard post={post} insights={true} Author={postWriter} />
                </Col>
              ))
            ) : (
              <Col xs={12} className="text-center">
                No Data
              </Col>
            )}
          </Row>
        </Container>
        {hasMore && (
          <Row className="mt-5">
            <Col className="d-flex justify-content-center">
              <div>
                <button className="btn_white2_border" onClick={handleLoadMore}>
                  Load more
                </button>
              </div>
            </Col>
          </Row>
        )}
      </section>
    </MainLayout>
  )
}

export default AuthorBlogTemplate

export const Head = () => {
  return (
    <SEORevamp
      title="Read Tech Expert Opinions | InvoZone Blog"
      description="Read the InvoZone blog to catch up on interesting insights and expert opinions related to software development, the Internet and technology."
    />
  )
}
